var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order-preview__container"},[_c('div',{staticClass:"order-preview__thumbnail-box"},[(!_vm.isBrokenImg && _vm.previewInfo.thumbnailImg)?_c('img',{staticClass:"order-preview__thumbnail-img",attrs:{"src":_vm.previewInfo.thumbnailImg},on:{"error":function($event){_vm.isBrokenImg = true}}}):_c('BrokenImageIcon'),(
        _vm.state === 'SOLDOUT' || _vm.state === 'NOT_EXIST' || _vm.state === 'EXPIRED'
      )?_c('div',{staticClass:"order-preview__thumbnail-sold-out"},[_vm._v(" 품절 ")]):(_vm.state === 'INVALID')?_c('div',{staticClass:"order-preview__thumbnail-sold-out"}):_vm._e()],1),_c('div',{staticClass:"order-preview__content"},[_c('p',{staticClass:"order-preview__name margin-bottom-6",class:{
        'order-preview__name--dimmed':
          _vm.state === 'SOLDOUT' ||
          _vm.state === 'NOT_EXIST' ||
          _vm.state === 'INVALID' ||
          _vm.state === 'EXPIRED',
      }},[_vm._v(" "+_vm._s(_vm.previewInfo.productName)+" ")]),_c('div',{staticClass:"order-preview__price-box margin-bottom-6"},[_c('div',{staticClass:"flex-wrapper--row margin-right-8"},[_c('p',{staticClass:"order-preview__price-sale margin-right-4",class:{
            'order-preview__price-sale--dimmed':
              _vm.state === 'SOLDOUT' ||
              _vm.state === 'NOT_EXIST' ||
              _vm.state === 'INVALID' ||
              _vm.state === 'EXPIRED',
          }},[_vm._v(" "+_vm._s(_vm.formattedPrice)+" ")]),_c('p',{staticClass:"order-preview__price-currency",class:{
            'order-preview__price-currency--dimmed':
              _vm.state === 'SOLDOUT' ||
              _vm.state === 'NOT_EXIST' ||
              _vm.state === 'INVALID' ||
              _vm.state === 'EXPIRED',
          }},[_vm._v(" 원 ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowDiscount),expression:"isShowDiscount"}],staticClass:"flex-wrapper--row"},[_c('p',{staticClass:"order-preview__price-market red-2 margin-right-4",class:{
            'order-preview__price-market--dimmed':
              _vm.state === 'SOLDOUT' ||
              _vm.state === 'NOT_EXIST' ||
              _vm.state === 'INVALID' ||
              _vm.state === 'EXPIRED',
          }},[_vm._v(" "+_vm._s(_vm.discountRate)+"% ")]),_c('p',{staticClass:"order-preview__price-market gray-5 line-through"},[_vm._v(" "+_vm._s(_vm.formattedMarketPrice)+" ")])])]),_c('div',{staticClass:"order-preview__option",class:{
        'order-preview__option--dimmed':
          _vm.state === 'SOLDOUT' ||
          _vm.state === 'NOT_EXIST' ||
          _vm.state === 'INVALID' ||
          _vm.state === 'EXPIRED',
      }},[(_vm.isInlineOptions)?[_c('p',[_c('span',[_vm._v(_vm._s(("수량: " + (_vm.previewInfo.quantity) + "개")))]),(_vm.selectedOptions)?_c('span',[_vm._v(_vm._s((" / 옵션: " + _vm.selectedOptions)))]):_vm._e()])]:[_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowQuantity),expression:"isShowQuantity"}]},[_vm._v(" "+_vm._s(("수량 : " + (_vm.previewInfo.quantity) + "개"))+" ")]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedOptions),expression:"selectedOptions"}]},[_vm._v(_vm._s(("옵션 : " + _vm.selectedOptions)))])]],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }