






















import {
  computed,
  defineComponent,
  getCurrentInstance,
  ref,
} from "@vue/composition-api";

import CheckIcon from "@/assets/ElementsImage/CheckIcon.vue";

export default defineComponent({
  components: {
    CheckIcon,
  },
  props: {
    label: {
      type: String,
    },
    name: {
      type: String,
    },
    id: {
      type: String,
    },
    value: {
      type: [String, Number, Object],
    },
    isChecked: {
      type: Boolean,
      default: undefined,
    },
    inputValues: {
      type: Array,
    },
    disabled: {
      type: Boolean,
    },
  },
  emits: ["change", "change:inputValues"],
  model: {
    prop: "inputValues",
    event: "change:inputValues",
  },
  setup(props, context) {
    const instance = getCurrentInstance();

    const computedId = computed(() => {
      return props.id ?? instance?.uid;
    });

    const uncontrolledChecked = ref(props.isChecked ?? false);
    const controlledChecked = computed(() => props.isChecked);
    const checked = computed(() => {
      if (props.disabled) {
        return false;
      } else if (props.value != null && props.inputValues != null) {
        return props.inputValues.includes(props.value);
      } else if (controlledChecked.value != null) {
        return controlledChecked.value;
      } else {
        return uncontrolledChecked.value;
      }
    });

    const onChange = (event: InputEvent) => {
      if (props.disabled) {
        return;
      }

      const target = event.target as HTMLInputElement;

      uncontrolledChecked.value = target.checked;

      let newValues = props.inputValues ?? [];

      if (!checked.value && props.value != null) {
        newValues = [...new Set([...newValues, props.value])];
      } else if (checked.value && props.value != null) {
        newValues = [
          ...new Set(
            newValues.filter(
              (value) => JSON.stringify(props.value) !== JSON.stringify(value)
            )
          ),
        ];
      }

      context.emit("change", event);
      context.emit("change:inputValues", newValues);
    };

    const computedCheckboxClass = computed(() => {
      return {
        "checkbox--disabled": props.disabled,
      };
    });

    const computedCheckIconColor = computed(() => {
      if (props.disabled) {
        return "#DEE2E6";
      }

      return "#FFFFFF";
    });

    const computedIndicatorClass = computed(() => {
      return {
        "checkbox__indicator--disabled": props.disabled,
        "checkbox__indicator--checked": checked.value,
      };
    });

    return {
      computedId,
      checked,
      onChange,
      computedCheckboxClass,
      computedCheckIconColor,
      computedIndicatorClass,
    };
  },
});
