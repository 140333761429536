
















import { defineComponent } from "@vue/composition-api";
export default defineComponent({
  props: {
    isBlur: Boolean,
    isTransparent: Boolean,
  },
});
