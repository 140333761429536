













































































































// types
import { OrderPreviewInfo } from "@/components/Product/Types";

// components
import BrokenImageIcon from "@/assets/ElementsImage/BrokenImageIcon.vue";

// hooks
import formatNumber from "@/components/Hooks/formatNumber";

import { defineComponent, ref, computed } from "@vue/composition-api";
export default defineComponent({
  components: { BrokenImageIcon },
  props: {
    previewInfo: {
      type: Object as () => OrderPreviewInfo,
      required: true,
    },
    isShowDiscount: { type: Boolean, default: true },
    isShowQuantity: { type: Boolean, default: true },
    isInlineOptions: { type: Boolean, detault: false },
    state: {
      type: String as () =>
        | "VALID"
        | "NOT_EXIST"
        | "SOLDOUT"
        | "NOT_ENOUGH"
        | "INVALID",
      default: "VALID",
    },
  },
  emits: [],
  setup(props) {
    const isBrokenImg = ref(false);

    const formattedPrice = computed(() =>
      formatNumber(props.previewInfo.price)
    );
    const formattedMarketPrice = computed(() => {
      if (!props.previewInfo.marketPrice) return 0;
      return formatNumber(props.previewInfo.marketPrice);
    });
    const discountRate = computed(() => {
      if (!props.previewInfo.marketPrice) return null;
      return (
        100 -
        Math.round(
          (props.previewInfo.price / props.previewInfo.marketPrice) * 100
        )
      );
    });

    const selectedOptions = computed(() => {
      const targetOptions = props.previewInfo.selectedOptions;
      let value = "";
      targetOptions.forEach((option: string, index: number) => {
        if (index + 1 !== targetOptions.length) {
          value += `${option}, `;
        } else {
          value += option;
        }
      });

      return value;
    });
    return {
      isBrokenImg,
      formattedPrice,
      formattedMarketPrice,
      discountRate,
      selectedOptions,
    };
  },
});
